@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import url('https://fonts.googleapis.com/css2?family=Honk&family=Poppins&display=swap');

body {
    margin: 0;
    font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI',
        'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 1rem;
    scroll-behavior: smooth;
}

.heading {
    font-family: 'Honk', sans-serif;
    font-size: 5rem;
    line-height: 1;
    color: #000;
}

@media (prefers-color-scheme: dark) {
    .heading {
        color: #fff;
    }
}


@media only screen and (max-width: 640px) {
    .heading {
        font-size: 3rem;
    }
}

@media only screen and (min-width: 640px) {
    .heading {
        font-size: 3rem;
    }
}

@media only screen and (min-width: 768px) {
   .heading {
        font-size: 4rem;
    }
}

@media only screen and (min-width: 1024px) {
    .heading {
        font-size: 5rem;
    }
}

@media only screen and (min-width: 1280px) {
    .heading {
        font-size: 5rem;
    }
}

@media only screen and (min-width: 1536px) {
    .heading {
        font-size: 5rem;
    }
}
